import { createApi } from '@reduxjs/toolkit/query/react';
import { configureStore } from '@reduxjs/toolkit';
import baseQuery from './baseQuery';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import commonReducer from './common/commonSlice';
import counteragentsReducer from './counteragents/counteragentsSlice';
import notificationsReducer from './notifications/notificationsSlice';

export const baseApi = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    'User', 'UserPermissions', 'Users', 'UserGroup', 'Groups', 'Workspace', 'Onboarding',
    'Counteragents', 'Timeslots',
    'Folders', 'Search', 'Favourites', 'FoldersTree',
    'Tariffs', 'TariffsHistory', 'CurrentTariff', 'TariffPromocode', 'TariffPrices', 'SignatureTariffs', 'SignatureLimit',
    'Groups',
    'Billing', 'BillingUserData', 'Cards', 'Subscription',
    'Facsimiles',
    'Product', 'Products', 'ProductsCategories',
    'Documents'
  ],
  endpoints: () => ({}),
});

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    user: userReducer,
    common: commonReducer,
    counteragents: counteragentsReducer,
    notifications: notificationsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([
    baseApi.middleware
  ])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
