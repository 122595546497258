import { FC } from 'react';
import { format } from 'date-fns';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Button, Table, TableRow, TableCell, LoadingIndicator } from '@forma/forma-ui-kit';
import { IInvoiceItem } from 'interfaces/billing.interface';

import styles from './billing-list.module.css';

interface BillingListProps {
  items?: IInvoiceItem[],
  isLoading: boolean
}

const BillingList: FC<BillingListProps> = ({ items, isLoading }) => {
  const { t } = useTranslation();

  const onClickItem = (url: string) => {
    window.open(url);
  };

  return (
    <div className={styles.root}>
      <Table columns={[
        { className: styles.cellIcon },
        { children: t('type'), className: styles.cellType },
        { children: t('document_name'), className: styles.cellName },
        { children: t('status'), className: styles.cellStatus },
        { children: t('sending_date'), className: styles.cellDate },
        { className: styles.cellButtons }
      ]} >
        {items?.map(({ id, name, filename, type, status, date }) => (
          <TableRow key={id}>
            <TableCell className={styles.cellIcon} onClick={() => onClickItem(filename)}>
              <img className={styles.docIcon} src="/icons/file_types/pdf.svg" alt="" />
            </TableCell>
            <TableCell className={styles.cellType} title={t('type')} onClick={() => onClickItem(filename)}>
              {t(type)}
            </TableCell>
            <TableCell className={styles.cellName} title={t('document_name')} onClick={() => onClickItem(filename)}>
              {name}
            </TableCell>
            <TableCell className={styles.cellStatus} title={t('status')} onClick={() => onClickItem(filename)}>
              {t(`signature_status.${status}`)}
            </TableCell>
            <TableCell className={styles.cellDate} title={t('sending_date')} onClick={() => onClickItem(filename)}>
              {format(new Date(date), 'dd.MM.yyyy')}
            </TableCell>
            <TableCell className={styles.cellButtons} onClick={() => onClickItem(filename)}>
              <Button
                className={styles.download}
                viewStyle="text"
                icon={<ReactSVG src="/icons/download.svg" wrapper="span" />}
                title={t('download')}
              />
            </TableCell>
          </TableRow>
        ))}

        {(!items?.length && !isLoading) && (
          <TableRow>
            <TableCell colSpan={10}>
              <div className={styles.noItems}>{t('search_nothing_found')}</div>
            </TableCell>
          </TableRow>
        )}
      </Table>

      {isLoading &&
        <div className={styles.loader}>
          <LoadingIndicator color="var(--grey-2)" />
        </div>
      }
    </div>
  );
};

export default BillingList;
