import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import { format } from 'date-fns';
import classNames from 'classnames';
import { SavedPaymentMethodsModal, BillingsModal, CancelSubscriptionModal } from 'pages/Tariffs';

import { ITariff } from 'interfaces/tariffs.interface';
import { IPaymentSubscription } from 'interfaces/billing.interface';

import styles from './subscription-info.module.css';

interface SubscriptionInfoProps {
  tariff?: ITariff,
  subscription?: IPaymentSubscription,
}

const SubscriptionInfo = ({ tariff, subscription }: SubscriptionInfoProps) => {
  const { t } = useTranslation();
  const [ openedModal, setOpenedModal ] = useState<'methods'|'billings'|'cancelSubscription'|null>(null);

  const { duration, dtTo } = tariff || {};
  const { id, name } = tariff?.tariff || {};
  const dateTo = dtTo && new Date(dtTo);

  const durationText = duration && (duration === 360 ? t('count_years', { count: 1 }) : t('count_months', { count: duration/30 }));
  const tariffWithDuration = name && `${name} ${durationText}`;

  return (
    <div className={styles.root}>
      <div className={classNames(styles.status, (dateTo && dateTo < new Date()) && styles.error)}>
        {(tariff && dateTo) && (
          id === 'tariff_trial' ? (
            dateTo > new Date() ? (
              t('subscription.trial_period_available_unitl', { date: format(dateTo, 'dd.MM.yyyy') })
            ) : (
              t('subscription.trial_period_expired', { date: format(dateTo, 'dd.MM.yyyy') })
            )
          ) : (
            !subscription?.id ? (
              dateTo > new Date() ? (
                t('subscription.your_tariff_available_until', { name: tariffWithDuration, date: format(dateTo, 'dd.MM.yyyy') })
              ) : (
                t('subscription.your_tariff_expired', { name: tariffWithDuration, date: format(dateTo, 'dd.MM.yyyy') })
              )
            ) : (
              dateTo > new Date() ? (
                <Trans
                  i18nKey="subscription.your_subscription_tariff_available_until"
                  values={{ name: tariffWithDuration, date: format(dateTo, 'dd.MM.yyyy'), price: subscription.amount }}
                />
              ) : (
                t('subscription.your_subscription_tariff_expired', { name: tariffWithDuration, date: format(dateTo, 'dd.MM.yyyy') })
              )
            )
          )
        )}
      </div>
      <div className={styles.buttons}>
        {subscription?.id && (
          <Button
            viewStyle="secondary"
            onClick={() => setOpenedModal('cancelSubscription')}
            className={styles.button}
          >
            {t('subscription.cancel_subscription')}
          </Button>
        )}

        <Button
          viewStyle="secondary"
          onClick={() => setOpenedModal('methods')}
          className={styles.button}
        >
          {t('subscription.payment_methods')}
        </Button>

        <Button
          viewStyle="secondary"
          onClick={() => setOpenedModal('billings')}
          className={styles.button}
        >
          {t('subscription.billings_and_acts')}
        </Button>

        <CancelSubscriptionModal open={openedModal === 'cancelSubscription'} onClose={() => setOpenedModal(null)} />
        <SavedPaymentMethodsModal open={openedModal === 'methods'} onClose={() => setOpenedModal(null)} />
        <BillingsModal open={openedModal === 'billings'} onClose={() => setOpenedModal(null)} />
      </div>
    </div>
  );
};

export default SubscriptionInfo;
