import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import BillingList from 'views/tariffs/BillingList/BillingList';

import { useGetInvoicesQuery } from 'store/billing/billingApi';

import styles from './BillingsModal.module.css';

interface BillingsModalProps {
  open?: boolean,
  onClose?: (open: boolean) => void,
}

const BillingsModal: FC<BillingsModalProps> = ({
  open, onClose
}) => {
  const { t } = useTranslation();
  const { data: invoices, isLoading: isInvoicesLoading } = useGetInvoicesQuery(undefined, { skip: !open });

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100%"
      maxWidth="985px"
      title={t('subscription.billings_and_acts')}
    >
      <div className={styles.modal}>
        <BillingList items={invoices} isLoading={isInvoicesLoading} />
      </div>
    </Modal>
  );
};

export default BillingsModal;
